import { useCallback, useEffect, useState } from 'react';
import { useSubscription } from '@apollo/client';
import { isInvoicesFirstLoadVar } from '../apollo/reactive-variables/invoices';
import { MY_DOCUMENTS, NEW_INVOICES_CHARGED } from '../graphql';
import { useFilteredQuery, useSelectedCompany } from '.';

const useNewInvoiceCharged = () => {
  const selectedCompany = useSelectedCompany();
  const [newInvoices, setNewInvoices] = useState(0);
  const { data } = useSubscription(NEW_INVOICES_CHARGED, {
    variables: {
      companyId: selectedCompany?.id,
    },
    skip: !selectedCompany,
    shouldResubscribe: true,
  });

  const { deleteQueryFromCache } = useFilteredQuery(MY_DOCUMENTS, {
    skip: true,
  });

  useEffect(() => {
    setNewInvoices(
      (previous) => previous + (data?.newInvoiceCharged?.newInvoices || 0),
    );
  }, [data]);

  useEffect(
    () => () => {
      setNewInvoices((previous) => {
        if (previous) {
          deleteQueryFromCache();
        }
        return 0;
      });
    },
    [],
  );

  const readData = useCallback(() => {
    setNewInvoices(0);
    isInvoicesFirstLoadVar(false);
  }, []);
  return [newInvoices, readData];
};

export default useNewInvoiceCharged;
