import { useReactiveVar } from '@apollo/client';
import currencyVar from '../apollo/reactive-variables/money';
import useSelectedCompany from './useSelectedCompany';

const useCreditLine = () => {
  const selectedCompany = useSelectedCompany();
  if (!selectedCompany) return null;

  const currency = useReactiveVar(currencyVar);
  const creditLines = selectedCompany.masterEntity.creditlines.edges.map((edge) => edge.node);
  if (currency) {
    return creditLines.find(
      (creditline) => creditline.currency.isoCode === currency,
    );
  }
  return creditLines[0];
};

export default useCreditLine;
