import { useMemo } from 'react';
import {
  FingoChristmas,
  FingoLogo,
  FingoChile,
  FingoHalloween,
} from '../assets';
import dayjs from '../config/dayjs';

const useDynamicFingoLogo = () => useMemo(
  () => ({
    9: FingoChile,
    10: FingoHalloween,
    12: FingoChristmas,
  }[dayjs().format('M')] || FingoLogo),
  [],
);
export default useDynamicFingoLogo;
