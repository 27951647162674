import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import GET_NATIONAL_INVOICE_PROVIDER from '../graphql/providers/queries';
import useSelectedCompany from './useSelectedCompany';
import useGetCountryFromUrl from './useGetCountryFromUrl';

const useNationalInvoiceProvider = () => {
  const company = useSelectedCompany();
  const countryFromUrl = useGetCountryFromUrl();

  const country = useMemo(
    () => company?.masterEntity?.country || countryFromUrl,
    [company, countryFromUrl],
  );

  const { data } = useQuery(GET_NATIONAL_INVOICE_PROVIDER, {
    variables: { countryId: country?.id },
  });

  return data?.getNationalInvoiceProvider;
};

export default useNationalInvoiceProvider;
